import React from 'react';
import Button from 'react-bootstrap/Button';
import DataAccess from './DataAccess';
import './DataEditor.css';

function DataEditor() {
  const copyToClipboard = () => {
    const textArea = document.getElementById('rf-dataeditor-text');
    textArea.focus();
    textArea.select();
    try {
      const success = document.execCommand('copy');
      const msg = success ? 'sucessful' : 'NOT successful';
      alert(`Copying data was ${msg}`);
    } catch (e) {
      alert('Error: Unable to copy, try manual copy/paste. This is likely related to your browser age or permissions.');
    }
    document.getElementById('rf-dataeditor-btn').focus();
  };

  const validateJSON = () => {
    const textArea = document.getElementById('rf-dataeditor-text');
    const data = textArea.value;
    try {
      JSON.parse(data);
      alert('Data is valid JSON format.');
    } catch (e) {
      alert('Error: Data is not in valid JSON format.');
    }
    document.getElementById('rf-dataeditor-btn').focus();
  };

  const updateData = () => {
    const textArea = document.getElementById('rf-dataeditor-text');
    const data = textArea.value;
    try {
      DataAccess.setAllDataRaw(data);
      alert('Data updated in local browser storage.');
    } catch (e) {
      alert('Error: Unable to update data to local browser storage.');    
    }
  };
  
  const clearAccessKey = () => {
    DataAccess.clearAccessKey();
  };

  const getDataFromStorage = () => {
    let data = '';
    try {
      data = DataAccess.getAllDataRaw();
    } catch (e) {
      data = e.toString();
    }
    return data;
  };

  return (
    <div className="">
        <h5>Data Editor</h5>
  <textarea id="rf-dataeditor-text" className="rf-dataeditor-text">{getDataFromStorage()}</textarea>       
        <Button 
          className="rf-dataeditor-button"
          variant="primary" 
          id="rf-dataeditor-btn"
          type="button" 
          onClick={copyToClipboard} 
          size="md"
        >
              Copy Data
        </Button> 
        <Button 
          className="rf-dataeditor-button"
          variant="secondary" 
          type="button" 
          onClick={validateJSON} 
          size="md"
        >
              Validate JSON
        </Button> 
        <Button 
          className="rf-dataeditor-button"
          variant="danger" 
          type="button" 
          onClick={updateData} 
          size="md"
        >
              Update Data
        </Button> 
        <Button 
          className="rf-dataeditor-button"
          variant="danger" 
          type="button" 
          onClick={clearAccessKey} 
          size="md"
        >
              Clear Access Key
        </Button> 
    </div>
  );
}

export default DataEditor;
