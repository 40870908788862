import React from 'react';

function History() {
  return (
    <div className="">
     Workout history page
     {/* <iframe style={{width:"90%"}} src="https://www.youtube.com/embed/i9u-svNHgao" 
     frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
     allowfullscreen></iframe> */}
    </div>
  );
}

export default History;
