import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Timer from './Timer';
import './StepRest.css';

function StepRest(props) {
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
      if (props.currentStepData != null && props.currentStepData.type === 'RE') {
        const event = new CustomEvent('statusColourChanged', { 'detail': 'READY' });
        window.dispatchEvent(event);
      }
    });
    if (props.currentStepData == null || props.currentStepData.type !== 'RE') {
      return null;
    }
    let nextExerciseInfo = '';
    if (props.nextStepData) {
      nextExerciseInfo = `Next exercise is ${props.nextStepData.name}`;
      if (props.nextStepData.weight != null && props.nextStepData.weight.length > 0) {
        nextExerciseInfo = `${nextExerciseInfo} (${props.nextStepData.weight})`;
      }
    }
       
    return (
      <>
        <div className="sr-restlabel">REST PERIOD</div>
        <div className="sr-resttimer">
          <Timer
            duration={props.currentStepData.duration} 
            paused={props.paused} 
            timeCompleteAction={props.currentStepData.autoNext === true ? props.timeCompleteAction : null} 
          /> 
        </div>
        <p /> 
        <div className="sr-restnextinfo"><p>{nextExerciseInfo}</p></div>
      </>
    );
  }

  StepRest.defaultProps = {
    paused: false,
    timeCompleteAction: null,
    nextStepData: null,
  };

  StepRest.propTypes = {
    currentStepData: PropTypes.object.isRequired,
    nextStepData: PropTypes.object,
    paused: PropTypes.bool,
    timeCompleteAction: PropTypes.func,
  };

export default StepRest;
