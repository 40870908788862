const MASTER_DATAKEY = 'rf-data-prod1';
const CURRENT_VERSION = '1';
const ACCESS_KEY = 'rf-data-key';

const DataKeys = {
    MasterKey() { return MASTER_DATAKEY; },
    AccessKey() { return ACCESS_KEY; },
    CurrentVersion() { return CURRENT_VERSION; },
};

export default DataKeys;
