import React from 'react';
import PropTypes from 'prop-types';
import { IconContext } from "react-icons";
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import DataAccess from './DataAccess';
import { FaAngleDoubleRight, FaPause, FaAngleDoubleLeft } from 'react-icons/fa';
import StepExerciseSimple from './StepExerciseSimple';
import StepWorkoutComplete from './StepWorkoutComplete';
import StepWorkoutStart from './StepWorkoutStart';
import StepRest from './StepRest';
import './ActiveWorkoutContainer.css'; 

class ActiveWorkoutContainer extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        currentStep: -1,
        routineData : {exercises : []},
        routineId : props.routineId,  
        paused: false
      }
    }
  
    handleChange = event => {
      const {name, value} = event.target
      this.setState({
        [name]: value
      })    
    }

    setStatusColour = event => {
      let statusCol;
      switch (event.detail) {
        case "ACTIVE":
          statusCol = "awc-workout-active";
          break;
        case "READY":
          statusCol = "awc-workout-ready";
          break;
        case "STOP":
          statusCol = "awc-workout-stop";
          break;    
        default:
          statusCol = "awc-workout-std";
          break;
      } 
      document.getElementById("rf-app-body").className = statusCol;    
    }
     
    handleSubmit = event => {
      event.preventDefault()
      const { email, username, password } = this.state
      alert(`Your registration detail: \n 
             Email: ${email} \n 
             Username: ${username} \n
             Password: ${password}`)
    }
    
    _next = () => {
      let currentStep = this.state.currentStep
      // check for end of workout (id 1000)
      currentStep = currentStep >= this.state.routineData.exercises.length-1 ? 1000: currentStep + 1
      this.setState({
        currentStep: currentStep
      })
    }
      
    _prev = () => {
      let currentStep = this.state.currentStep
      currentStep = currentStep <= -1 ? -1: currentStep - 1
      this.setState({
        currentStep: currentStep
      })
    }

    _pause = () => {
      //alert("paused");
      this.setState({paused : !this.state.paused})
    }
  
  /*
  * the functions for our button
  */
  previousButton() {
    let currentStep = this.state.currentStep;
    if(currentStep !==-1 && currentStep !== 1000){
      return (
        <Button 
          className="btn btn-secondary awo-button" 
          type="button" onClick={this._prev} size="lg">
          <FaAngleDoubleLeft />  
          {/* Previous Exercise */}
        </Button> 
      )
    }
    return null;
  }
  
  nextButton(){
    let buttonText = '';
    let currentStep = this.state.currentStep;
    if(currentStep < this.state.routineData.exercises.length){
      if (currentStep < 0) {
        buttonText = "Start Workout";
      }
      else
      {
        buttonText = (this.state.routineData.exercises[currentStep].type === 'RE') ? "Next" : "Next "
      }
      return (
        <Button 
          className="btn btn-primary float-right awo-button" 
          type="button" onClick={this._next} size="lg"> 
          {buttonText}
          {/* <FaAngleRight /> */}
          <FaAngleDoubleRight />
        </Button>   
      )
    }
    return null;
  }
    
  pauseButton(){
    let currentStep = this.state.currentStep;
    if(currentStep < 1000 && currentStep > -1){
      return (
        <Button 
          className="btn btn-primary float-right awo-button" 
          size="lg" type="button" onClick={this._pause}>
          <FaPause /> 
          {/* Pause Workout */}          
        </Button>        
      )
    }
    return null;
  }

  componentDidMount() {
    window.addEventListener('statusColourChanged', this.setStatusColour );
    
    // get the routine data from storage 
    const routine = DataAccess.getRoutineById(this.state.routineId);
    this.setState({ routineData : routine });
  }

  componentWillUnmount() {
    window.removeEventListener('statusColourChanged', function (e) { this.setStatusColour(e.status); }, false);
  }

    render() {    
      return (
        <React.Fragment>
        <IconContext.Provider value={{ color: "white", className: "global-class-name", size: "2rem", style: { verticalAlign: 'middle' } }}>
        <div className="awc-workout-std" id="awo-body">
          <Container fluid>
            <Row>
              <Col>Active Workout</Col>
            </Row>
            <Row>
              <Col>
                <div className="awo-workoutactionspanel">
                <StepWorkoutStart 
                  currentStep={this.state.currentStep} 
                  handleChange={this.handleChange}
                />
                <StepExerciseSimple 
                  currentStep={this.state.currentStep} 
                  currentStepData={this.state.routineData ? this.state.routineData.exercises[this.state.currentStep] : null}
                  handleChange={this.handleChange}
                  setStatusColour={this.setStatusColour}
                  paused={this.state.paused}
                  timeCompleteAction={this._next}
                />
                <StepRest
                  currentStep={this.state.currentStep} 
                  currentStepData={this.state.routineData ? this.state.routineData.exercises[this.state.currentStep] : null}
                  nextStepData={this.state.routineData ? (this.state.routineData.exercises[this.state.currentStep+1] ? this.state.routineData.exercises[this.state.currentStep+1] : null) : null }
                  handleChange={this.handleChange}
                  setStatusColour={this.setStatusColour}
                  paused={this.state.paused}
                  timeCompleteAction={this._next}
                />
                <StepWorkoutComplete
                  currentStep={this.state.currentStep} 
                  handleChange={this.handleChange}
                  password={this.state.password}
                />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <hr />
                <div className="awo-buttonssection">
                  {this.previousButton()}
                  {this.nextButton()}
                  {this.pauseButton()}
                  {/* {this.abandonButton()} */}   
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        </IconContext.Provider>
        </React.Fragment>
      );
    }
}

ActiveWorkoutContainer.propTypes = {
  routineId: PropTypes.string // ID in JSON for the routine
};

export default ActiveWorkoutContainer;
