import React from 'react';
import { FaAngleRight, FaAngleLeft } from 'react-icons/fa';
import { IconContext } from "react-icons";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import SubStepExerciseSets from './SubStepExerciseSets';
import SubStepRest from './SubStepRest';
import PropTypes from 'prop-types';
import './SubStepSetsContainer.css';

class SubStepSetsContainer extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        currentSet: 1,
        resting: false,
      }
    }
  
    // handleChange = event => {
    //   const {name, value} = event.target
    //   this.setState({
    //     [name]: value
    //   })    
    // };
     
    // handleSubmit = event => {
    //   event.preventDefault()
    //   const { password } = this.state
    //   alert(`Your registration detail: \n 
    //          Password: ${password}`)
    // }
    
    _next = () => {      
      if(this.state.resting) {
        let currentSet = this.state.currentSet
        // check for end of workout (id 1000)
        currentSet = currentSet >= this.props.setCount ? 1000: currentSet + 1
        this.setState({
          currentSet: currentSet,
          resting: false
        })
      }
      else {
        this.setState({
          resting: true
        })
      }     
    }
      
    _prev = () => {
      let currentSet = this.state.currentSet;
      currentSet = currentSet <= -1 ? -1: currentSet - 1
      this.setState({
        currentSet: currentSet
      })
    }

    _pause = () => {
      alert("paused");
    }
  
  /*
  * the functions for our button
  */
  previousButton() {
    let currentSet = this.state.currentSet;
    if(currentSet > 1 && currentSet !== 1000){
      return (
        <Button 
          className="btn btn-secondary ssc-button" 
          type="button" onClick={this._prev} size="lg">
          <FaAngleLeft /> 
          {/* Previous Set */}
        </Button>
      )
    }
    return null;
  }
  
  nextButton(){
    let currentSet = this.state.currentSet;
    if(currentSet < this.props.setCount){
      const buttonText = this.state.resting ? 'Rest Done' : 'Set Done';
      return (
        <Button 
          className="btn btn-primary float-right ssc-button" 
          type="button" onClick={this._next} size="lg">
          {buttonText} <FaAngleRight />
        </Button>        
      )
    }
    return null;
  }
    
  pauseButton(){
    let currentStep = this.state.currentStep;
    if(currentStep <3){
      return (
        <Button 
          className="btn btn-primary float-right ssc-button" 
          type="button" onClick={this._pause} size="lg">
        Pause Workout
        </Button>        
      )
    }
    return null;
  }

  restBreak() {
    if (this.state.resting) {
      return (
        <SubStepRest duration={this.props.currentStepData.restafterset} paused={this.props.paused} autoNext={this.props.currentStepData.autoNext} timeCompleteAction={this._next}/> 
      )
    }
  }

  componentDidMount() {

  }

    render() {    
      return (
        <React.Fragment>
        <IconContext.Provider value={{ color: "white", className: "global-class-name", size: "2rem", style: { verticalAlign: 'middle' } }}>
          <Container fluid="sm">
            <Row>
              <Col>
                <SubStepExerciseSets
                  resting={this.state.resting}
                  currentStepData={this.props.currentStepData} 
                  currentSet={this.state.currentSet} 
                  setsData={this.props.setsData}
                  restAfterSet={this.props.restAfterSet}
                  setCount={this.props.setCount}
                  timeCompleteAction={this._next}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="ssc-buttonssection">
                  {this.restBreak()}
                  {this.previousButton()}
                  {this.nextButton()}
                  {this.pauseButton()}
                </div>
              </Col>
            </Row>
          </Container>
          </IconContext.Provider>
        </React.Fragment>
      );
    }
}

SubStepSetsContainer.defaultProps = {
  paused: false,
  restAfterSet: 0,
};

SubStepSetsContainer.propTypes = {
  setCount: PropTypes.number.isRequired,
  paused: PropTypes.bool,
  currentStepData: PropTypes.object.isRequired,
  setsData: PropTypes.array.isRequired,
  restAfterSet: PropTypes.number,
};

export default SubStepSetsContainer;
