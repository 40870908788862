import React from 'react';
import DataEditor from '../DataEditor';

function Data() {
  return (
    <div className="">
     <DataEditor />
    </div>
  );
}

export default Data;
