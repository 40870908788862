import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Timer from './Timer';
import SubStepSetsContainer from './SubStepSetsContainer';
import './StepExerciseSimple.css';

function StepExerciseSimple(props) {
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
      if (props.currentStepData != null && props.currentStepData.type === 'XE') {
        const event = new CustomEvent('statusColourChanged', { 'detail': 'ACTIVE' });
        window.dispatchEvent(event);
      }
    });
    // only show for the right types of steps 
    if (props.currentStepData == null || props.currentStepData.type !== 'XE') {
       return null;
    }

    // if duration set then we need a timer showing 
    let stepTimer = '';
    if (props.currentStepData.duration) {
      stepTimer = (
        <Timer 
          duration={props.currentStepData.duration} 
          paused={props.paused} 
          timeCompleteAction={props.currentStepData.autoNext === true ? props.timeCompleteAction : null}
        />
      );
    } 
    
    let subSteps = null;
    if (props.currentStepData.reps !== null && props.currentStepData.reps.length > 0) {
      subSteps = (
        <SubStepSetsContainer 
          currentStepData={props.currentStepData} 
          setsData={props.currentStepData.reps} 
          setCount={props.currentStepData.reps.length}
          restAfterSet={props.currentStepData.restafterset}
          paused={props.paused}
          timeCompleteAction={props.timeCompleteAction}
        />
      );
    }

    // const subStepsNextButton = subSteps !== null ? <button className="" type="button">Set Done</button>: ""; 

    return (
      <>
        <div className="ses-currentexercise-name"><p>{props.currentStepData.name}</p></div>
        <div className="ses-currentexercise-notes"><p>{props.currentStepData.notes}</p></div>
        <div className="ses-currentexercise-url">
          <p><a href={props.currentStepData.url} target="_blank" rel="noopener noreferrer">{props.currentStepData.url}</a></p>
        </div>
        {stepTimer}
        <div className="ses-currentexercise-weight"><p>{props.currentStepData.weight ? `Weight: ${props.currentStepData.weight}` : ''}</p></div>
        {subSteps}
        {/* {subStepsNextButton} */}
      </>      
    );
  }

  // Specifies the default values for props:
  StepExerciseSimple.defaultProps = {
    paused: false,
    autoNext: false,
    timeCompleteAction: null,
  };

  StepExerciseSimple.propTypes = {
    currentStepData: PropTypes.string.isRequired,
    paused: PropTypes.bool,
    autoNext: PropTypes.bool,
    timeCompleteAction: PropTypes.func,
  };
  
export default StepExerciseSimple;
