import DataKeys from './DataKeys';

const DataAccess = {
    // set access flag so not asked again
    setAccessKey() {
        localStorage.setItem(DataKeys.AccessKey, new Date().getTime());
    },
    // clear access flag 
    clearAccessKey() {
        localStorage.setItem(DataKeys.AccessKey, '');
    },
    // return true/false if have valid access key in local storage
    accessKeyValid() {
        const key = localStorage.getItem(DataKeys.AccessKey);
        return key ? key.length > 0 : false;
    },
    // get all data from storage as string
    getAllDataRaw() {
        return localStorage.getItem(DataKeys.MasterKey);
    },
    // set all data to storage as string
    setAllDataRaw(data) {
        localStorage.setItem(DataKeys.MasterKey, data);
        /// TODO handle error here 
    },
    // get all data from storage as object
    getAllData() {
        const datastr = localStorage.getItem(DataKeys.MasterKey);
        const allDataObj = JSON.parse(datastr);
        /// TODO handle error here 
        return allDataObj;
    },
    // set all data to storage as object
    setAllData(data) {
        const dataToSave = JSON.stringify(data);
        localStorage.setItem(DataKeys.MasterKey, dataToSave);
        /// TODO handle error here 
    },
    // get a routine by routine id
    getRoutineById(routineId) {
        const allData = this.getAllData();
        return allData.rfdata.routines.find((obj) => obj.id === routineId);
    },
    // get array of routines (name and id)
    getRoutinesList() {
        const routineList = [];
        const allData = this.getAllData();
        if (allData) {
            const rawRoutines = allData.rfdata.routines;
            if (rawRoutines && rawRoutines.length > 0) {
                rawRoutines.forEach((element) => {
                    const newRListItem = {
                        name: element.name,
                        id: element.id,
                    };
                    routineList.push(newRListItem);
                });
            }
        }
        return routineList;
    },
};

export default DataAccess;
