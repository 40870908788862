import React from 'react';
import Container from 'react-bootstrap/Container';
import { IconContext } from 'react-icons';
import { FaDumbbell, FaClipboardList, FaHistory } from 'react-icons/fa';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './Home.css';

function Home() {
  return (
    <div className="">
      <header className="rf-home-masthead">
        <Container className="rf-home-container">
            {/* <div className="rf-home-masthead-subheading">Welcome!</div> */}
            {/* <div className="rf-home-masthead-heading rf-app-text-uppercase">It's Nice To Meet You</div> */}
            <div className="rf-home-masthead-heading rf-app-text-uppercase"><img className="rh-home-header-image" src="/ttb_logo_named_inverted_blk2.png" alt="The Training Buddy Logo" /></div>
            {/* <a className="btn btn-primary rf-home-btn-more rf-app-text-uppercase" href="#services">Tell Me More</a> */}
            <div className="rf-home-masthead-subheading">The workout application to keep you focused</div>
        </Container>
      </header>
      <section className="rf-home-page-section" id="services">
        <IconContext.Provider value={{
            color: 'black', className: 'global-class-name', size: '4.5rem', style: { verticalAlign: 'middle' },
            }}
        >
            <Container className="rf-home-container">
                <div className="rf-app-text-center">
                    <h2 className="rf-home-section-heading rf-app-text-uppercase">Features</h2>
                    <h3 className="rf-home-section-subheading rf-home-muted">Just some of the features available to make your workouts easier.</h3>
                </div>
                <Row class="rf-app-text-center">
                    <Col md={4}>
                        <FaClipboardList />
                        <h4 className="rf-home-svcs-title">Custom Routines</h4>
                        <p className="rf-home-muted">Use the Routine Builder to build the workout routines how you like them, then choose which one you want to perform when you like. All data is held locally so it is in your control.</p>
                    </Col>
                    <Col md={4}>
                        <FaDumbbell />
                        <h4 className="rf-home-svcs-title">Interactive Workouts</h4>
                        <p className="rf-home-muted">The Workout Buddy provides a step by step prompt as you progress through your routine. It&amp;ll keep track of your time and the upcoming sets so you can keep your focus.</p>
                    </Col>
                    <Col md={4}>
                        <FaHistory />
                        <h4 className="rf-home-svcs-title">Workout History</h4>
                        <p className="rf-home-muted">Not only is The Workout Buddy there with you each workout, we also keep each workout as a history so you can see what you did and when.</p>
                    </Col>
                </Row>
            </Container>
        </IconContext.Provider>
      </section>
      <section className="rf-home-page-section" id="getstarted">
        <IconContext.Provider value={{
            color: 'black', className: 'global-class-name', size: '4.5rem', style: { verticalAlign: 'middle' },
            }}
        >
            <Container className="rf-home-container">
                <div className="rf-app-text-center">
                    <h2 className="rf-home-section-heading rf-app-text-uppercase">So how does it work?</h2>
                </div>
                <Row class="rf-app-text-center">
                    <Col md={4}>
                        <div className="rf-home-steps-num">1</div>
                        <h4 className="rf-home-svcs-title">Build a routine</h4>
                        <p className="rf-home-muted">Use the Routine Builder to enter your own custom routine with sets, reps, times and even video URLs. Create multiple routines to suit your needs.</p>
                    </Col>
                    <Col md={4}>
                        <div className="rf-home-steps-num">2</div>
                        <h4 className="rf-home-svcs-title">Do a workout</h4>
                        <p className="rf-home-muted">Go to Workout Now to start a workout, choosing the routine you want to use, and then follow the routine prompts step by step.</p>
                    </Col>
                    <Col md={4}>
                        <div className="rf-home-steps-num">3</div>
                        <h4 className="rf-home-svcs-title">Review workout history</h4>
                        <p className="rf-home-muted">After your workout you can choose to save your workout and then review your results in and progress over time.</p>
                    </Col>
                </Row>
            </Container>
        </IconContext.Provider>
      </section>
    </div>
  );
}

export default Home;
