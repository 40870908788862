import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

function StepWorkoutComplete(props) {
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
      if (props.currentStep === 1000) {
        // eslint-disable-next-line no-undef
        const event = new CustomEvent('statusColourChanged', { detail: 'RESET' });
        window.dispatchEvent(event);
      }
    });

    const navigateToHistoryPage = () => {
      window.location.href = '/history/';
    };

    const navigateToHomePage = () => {
      window.location.href = '/';
    };

    // step 1000 is end of workout
    if (props.currentStep !== 1000) {
      return null;
    } 
    
    return (
      <>
        <p>Workout Complete - Great Job !!!</p>
      <p>
        <Button
          className="btn btn-success" 
          size="lg" 
          onClick={navigateToHistoryPage}
        >
          Save This Workout
        </Button> 
      </p>
      <p>
        <Button 
          className="btn btn-secondary" 
          size="lg"
          onClick={navigateToHomePage}
        >
          Don't Save This Workout
        </Button>
      </p>
      </>
    );  
  }

  StepWorkoutComplete.propTypes = {
    currentStep: PropTypes.number.isRequired,
  };

  export default StepWorkoutComplete;
