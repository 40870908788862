import React, { Component } from 'react';
import { IconContext } from 'react-icons';
import PropTypes from 'prop-types';
import './Timer.css';

export default class Timer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            minutes: (Math.floor(this.props.duration / 60)),
            seconds: (this.props.duration - (Math.floor(this.props.duration / 60) * 60)),
            expired: false,
            paused: false,
        };
        this.pause = this.pause.bind(this);
      }

    componentDidMount() {
        this.myInterval = setInterval(() => {
            const { seconds, minutes } = this.state;

            if (this.state.paused || this.props.paused) {
                return;
            }

            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1,
                }));
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(this.myInterval);
                } else {
                    this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59,
                    }));
                }
            } 
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.myInterval);
    }

    playAudio() {
        const audio = new Audio('/timesupbeep.mp3');
        audio.play();
    } 

    pause() {
        // toggle pause state
        this.setState({paused: !this.state.paused});
    }

    render() {
        //  // only show for the right types of steps 
        // if (this.props.duration && this.props.duration > 0) {
        //     return null;
        // }

        const { minutes, seconds } = this.state;
        // if times expired then play audio and mark as expired 
        if (minutes === 0 && seconds === 0 && !this.state.expired) {
            this.playAudio();
            this.setState({expired: true});
            if (this.props.timeCompleteAction != null) {
                this.props.timeCompleteAction();
            }
        }
        // update display text to show time remaining, or paused or clock 
        let display = '';
        if (this.props.duration) {
            if (minutes === 0 && seconds === 0) {
                display = 'Times Up!';
            } else if (this.props.paused) {
                display = 'PAUSED';
            } else {
                const secondsDisplay = seconds < 10 ? `0${seconds}` : seconds;
                display = `${minutes}:${secondsDisplay}`;   
            }
        }
    
        return (
            <div>
                <IconContext.Provider 
                  value={{
                        color: 'black', className: 'global-class-name', size: '1.5rem', style: { verticalAlign: 'middle' },
                    }}
                >
                {/* <FaHourglassHalf /> */}
                <p />
                <div className="timer-countdown"><p>{display}</p></div>
                {/* <button onClick={this.pause}><FaPause/></button> */}
                </IconContext.Provider>
            </div>
        );
    }
}

Timer.defaultProps = {
    paused: false,
    timeCompleteAction: null,
  };
  
  Timer.propTypes = {
    duration: PropTypes.number.isRequired,
    timeCompleteAction: PropTypes.func,
    paused: PropTypes.bool,
  };
