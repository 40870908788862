import React from 'react';
import DataAccess from '../DataAccess';

// import history from 'history/browser'; //singleton option 
// import { createBrowserHistory  } from 'history';
// import { useHistory } from  'react-router-dom';

class WorkoutNow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {selectedRoutine: '0'};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({selectedRoutine: event.target.value});
  }

  handleSubmit(event) {
    // navigate to the workout route with the selected routine id 
    // history.push('/history')
    if (this.state.selectedRoutine !== '0') {
      // eslint-disable-next-line no-undef
      window.location.href = `/workout/${this.state.selectedRoutine}`;
    }
    event.preventDefault(); 
  }

  render() {
    // let history = useHistory();
    const routineList = DataAccess.getRoutinesList();
    return (
      <div className="">
        <h4>Workout Now page</h4> 
        <p>Ready to workout now? Pick a routine...</p>
        <form onSubmit={this.handleSubmit}>
            <label htmlFor="rf-won-routine-dd">
              Routine:
              <select id="rf-won-routine-dd" value={this.state.selectedRoutine} onChange={this.handleChange} onClick={this.handleChange}>
                <option value="0" key="rf-won-option-0"> </option>
                {routineList.map((item) => <option key={`rf-won-option-${item.id}`} value={item.id}>{item.name}</option>)}
              </select>
            </label>
            <input type="submit" value="Submit" />  
            {/* <Button variant="btn btn-success" onClick={() => history.push('/history')}>Click button</Button> */}
        </form>
      </div>    
    );
  }
}

export default WorkoutNow;
