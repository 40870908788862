import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Timer from './Timer';
import './SubStepRest.css';

function SubStepRest(props) {
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
      const event = new CustomEvent('statusColourChanged', { 'detail': 'READY' });
      window.dispatchEvent(event);
  });
  return (
    <>
      <div className="ssr-restlabel">REST</div>
      <div className="ssr-resttimer">
        <Timer duration={props.duration} paused={props.paused} timeCompleteAction={props.autoNext === true ? props.timeCompleteAction : null} /> 
      </div>
    </>
  );
}

SubStepRest.defaultProps = {
  paused: false,
  duration: 0,
  autoNext: false,
  timeCompleteAction: null,
};

SubStepRest.propTypes = {
  paused: PropTypes.bool,
  duration: PropTypes.number,
  autoNext: PropTypes.bool,
  timeCompleteAction: PropTypes.func,
};

export default SubStepRest;
