import React from 'react';

function Routines() {
  return (
    <div className="">
     Routines page
    </div>
  );
}

export default Routines;
