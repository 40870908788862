import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { IconContext } from 'react-icons';
import './StepWorkoutStart.css';
import { FaDumbbell } from 'react-icons/fa';

function StepWorkoutStart(props) {
  useEffect(() => {
      const event = new CustomEvent('statusColourChanged', { 'detail': 'RESET' });
      window.dispatchEvent(event);
  });
    if (props.currentStep !== -1) {
      return null;
    } 
    return (
      <div className="sws-active-wo-body">
        <IconContext.Provider value={{
          color: 'black', className: 'global-class-name', size: '5rem', style: { verticalAlign: 'middle' },
          }}
        >
        <p>Get Ready To Start Workout...</p>
        <p>
          <FaDumbbell />
        </p>          
        </IconContext.Provider>
      </div>
      
    );
  }

  StepWorkoutStart.propTypes = {
    currentStep: PropTypes.number.isRequired,
  };

export default StepWorkoutStart;
