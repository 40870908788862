import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, useParams } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import PropTypes from 'prop-types';
import FooterComponent from './FooterComponent';
import ActiveWorkoutContainer from './ActiveWorkoutContainer';
import WorkoutNow from './Pages/WorkoutNow';
import Routines from './Pages/Routines';
import Home from './Pages/Home';
import History from './Pages/History';
import About from './Pages/About';
import Login from './Pages/Login';
import './App.css';
import Data from './Pages/Data';

function ActiveWorkoutContainerWrapper(props) {
  const { id } = useParams();
  props.setWorkoutInProgress(true);
  return <ActiveWorkoutContainer routineId={id} />;
}

function App() {
  const [workoutInProgress, setWorkoutInProgress] = useState(false);
  return (
    <Router>    
    <div id="rf-app-body"> 
      <div className="App">
        <div className="rf-app-container">
          <div className="rf-app-content-wrap">            
            <Navbar bg="dark" variant="dark" collapseOnSelect expand="sm">
              <Navbar.Brand href="/">
                <img src="/ttb_logo_inverted.png" alt="The Training Buddy Logo" height="25" />
                TheTrainingBuddy.com
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className={workoutInProgress ? 'rf-app-navbar-hidden' : ''}>
                  <Nav.Link href="/">Home</Nav.Link>
                  <Nav.Link href="/workout">Workout Now</Nav.Link>
                  <Nav.Link href="/routines">Routines</Nav.Link>
                  <Nav.Link href="/history">History</Nav.Link>
                  <Nav.Link href="/data">Data</Nav.Link>
                  <Nav.Link href="/about">About</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            <Switch>
              <Route path="/workout/:id">
                <ActiveWorkoutContainerWrapper setWorkoutInProgress={setWorkoutInProgress} />
              </Route>
              <Route path="/workout">
                <WorkoutNow />
              </Route>
              <Route path="/routines">
                <Routines />
              </Route>
              <Route path="/history">
                <History />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/data">
                <Data />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/">
                <Home />
              </Route>              
            </Switch>
          </div>
          <div className="rf-app-footer">
              <FooterComponent />
          </div>
        </div>
      </div>
    </div>
    </Router>
  );
}

ActiveWorkoutContainerWrapper.defaultProps = {
  setWorkoutInProgress: false,
};

ActiveWorkoutContainerWrapper.propTypes = {
  setWorkoutInProgress: PropTypes.bool,
};

export default App;
