import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Timer from './Timer';
import './SubStepExerciseSets.css';

function SubStepExerciseSets(props) {
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
      if (!props.resting) {
        const event = new CustomEvent('statusColourChanged', { 'detail': 'ACTIVE' });
        window.dispatchEvent(event);
      }
    });
    // only show for the right types of steps 
     if (props.resting) {
        return null;
     }

    // if duration set then we need a timer showing 
    const stepTimer = props.currentStepData.repDuration !== '' ? <Timer duration={props.currentStepData.repDuration} paused={props.paused} timeCompleteAction={props.currentStepData.autoNext === true && (props.currentSet < props.setsData.length) ? props.timeCompleteAction : null} /> : ''; 
    
    const repCount = (props.setsData[props.currentSet - 1] === '0' || props.setsData[props.currentSet - 1] === 0) ? '' : `${props.setsData[props.currentSet - 1]} reps`;

    return (
        <>  
          <p />
          {stepTimer}
          <div className="sses-setcounter">
            <p>{`Set ${props.currentSet} of ${props.setCount}`}</p>
          </div>
          <div className="sses-repstodo">
            <p>{repCount}</p>
          </div>
        </>
    );
  }

  SubStepExerciseSets.defaultProps = {
    resting: false,
    setsData: [],
    paused: false,
    timeCompleteAction: null,
    setCount: 0,
  };

  SubStepExerciseSets.propTypes = {
    currentStepData: PropTypes.object.isRequired,
    setsData: PropTypes.array,
    currentSet: PropTypes.number.isRequired,
    resting: PropTypes.bool,
    paused: PropTypes.bool,
    timeCompleteAction: PropTypes.func,
    setCount: PropTypes.number,
  };

export default SubStepExerciseSets;
