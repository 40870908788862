/* eslint-disable object-curly-newline */
import React, { useState } from 'react';
import { Button, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import './Login.css';
import DataAccess from '../DataAccess';

function Login() {
  // const [email, setEmail] = useState("");
  const [password, setPassword] = useState('');

  function validateForm() {
    // return email.length > 0 && password.length > 0;
    return password.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (password === 'arnold') {
      DataAccess.setAccessKey();
      // eslint-disable-next-line no-undef
      window.location.href = '/';
    } else {
      // eslint-disable-next-line no-undef
      alert('Early access password not accepted.'); 
      setPassword('');
    } 
  }

  return (
    <div className="Login">
      <p>This is site is currently at private BETA stage and not yet available for sign up. If you have an early access password please enter below.</p>
      <form onSubmit={handleSubmit}>
        {/* <FormGroup controlId="email" bsSize="large">
          <FormLabel>Email</FormLabel>
          <FormControl
            autoFocus
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </FormGroup> */}
        <FormGroup controlId="password" bsSize="large">
          <FormLabel>Enter your invite password</FormLabel>
          <FormControl
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />
        </FormGroup>
        <Button block bsSize="large" disabled={!validateForm()} type="submit">
          Login
        </Button>
      </form>
    </div>
  );
}

export default Login;
